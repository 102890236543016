<template>
  <div class="install">
    <h1 class="install__title mt-0">
      {{ $t("c.channels.widget.install_title") }}
    </h1>
    <p class="install__description">
      {{ $t("c.channels.widget.install_description") }}
    </p>
    <h3 class="install__title">
      {{ $t("c.channels.common.step", { num: 1 }) }}
    </h3>
    <FormControl
      class="mb-4"
      :label="$t('c.channels.widget.domain_label')"
      :error="errorMessageDomain"
    >
      <Input
        v-model="domainName"
        type="text"
        :has-errors="errorMessageDomain ? true : false"
        :is-loading="isDomainLoading"
        placeholder="http://"
        @keyup.enter="$event.target.blur()"
        @blur="updateDomainName"
      />
    </FormControl>
    <h3 class="install__title">
      {{ $t("c.channels.common.step", { num: 2 }) }}
    </h3>
    <p class="install__description">
      {{ $t("c.channels.widget.second_step_desctiption") }}
    </p>
    <CopyCode :code="widgetScript" />
    <h3 class="install__title">
      {{ $t("c.channels.common.step", { num: 3 }) }}
    </h3>
    <p class="install__description">
      {{ $t("c.channels.widget.third_step_desctiption") }}
    </p>
    <CopyCode :code="widgetAnchor" />
    <div class="install__share">
      <div class="p-2">
        <h3 class="install__share-title">
          {{ $t("c.channels.widget.share_title") }}
        </h3>
        <p class="install__share-description">
          {{ $t("c.channels.widget.share_description") }}
        </p>
      </div>
      <div class="p-2">
        <Button class="text-nowrap" @click="openShareModal">
          {{ $t("c.channels.widget.share_instructions_button") }}
        </Button>
      </div>
    </div>
    <VerificationButton
      class="text-center mt-5"
      :status="status"
      :is-loading="isVerificationLoading"
      :channel="channels.WEBSITE"
      :on-click="verifyWebsite"
    />
  </div>
</template>

<script>
import GET_BUSINESS_DOMAIN from "@/modules/sales-channels/graphql/GetBusinessDomain.gql";
import GET_SALES_CHANNEL from "@/modules/sales-channels/graphql/GetSalesChannel.gql";
import UPDATE_DOMAIN_NAME_FOR_BUSINESS from "@/modules/sales-channels/graphql/UpdateDomainNameForBusiness.gql";
import VERIFY_SALES_CHANNEL from "@/modules/sales-channels/graphql/VerifySalesChannel.gql";
import WidgetModal from "../../components/WidgetModal.vue";
import VerificationButton from "../../components/Button/VerificationButton.vue";
import { channels, statuses } from "@/utils/enums/channels";
import { FormControl, Input, Button, CopyCode, Toast } from "@/design-system";
import { getActiveBusiness } from "@/utils/mixins";
import { pathOr, has } from "ramda";

export default {
  name: "InstallWidget",
  components: {
    FormControl,
    Input,
    Button,
    CopyCode,
    VerificationButton,
  },
  mixins: [getActiveBusiness],
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    errorMessageDomain: null,
    domainLoading: false,
    verificationInProgress: false,
    channels,
    salesChannel: {
      username: null,
      verificationStatus: null,
    },
  }),
  apollo: {
    domainName: {
      query: GET_BUSINESS_DOMAIN,
      variables() {
        return {
          businessId: this.businessId,
        };
      },
      update(response) {
        return response.business.domainName;
      },
    },
    salesChannel: {
      query: GET_SALES_CHANNEL,
      variables() {
        return {
          businessId: this.businessId,
          salesChannelEnum: channels.WEBSITE,
        };
      },
      update(response) {
        const channel = response.business.salesChannels[0];
        return {
          username: channel.username,
          verificationStatus: channel.state,
        };
      },
    },
  },
  computed: {
    widgetScript() {
      /*eslint-disable */
      return `<script type="text/javascript">\n\t(function (w, d, s, f) {\n\t\tw["ChatFoodWidgetClient"] = "${this.activeBusiness.url}";\n\t\tvar p = d.createElement(s);\n\t\tp.type = "text/javascript";\n\t\tp.async = true; p.src = f;\n\t\tvar c = d.getElementsByTagName("script")[0];\n\t\tc.parentNode.insertBefore(p, c);\n\t})(window, document, "script", "https://widget.chatfood.io/widget.v1.js")\n<\/script>`
      /*eslint-enable */
    },
    widgetAnchor() {
      return `<a href="javascript:;" target="_blank" onclick="ChatFoodWidget.orderNow()">Test link</a>`;
    },
    status() {
      return this.salesChannel.verificationStatus ?? statuses.INACTIVE;
    },
    isDomainLoading() {
      return this.domainLoading || this.$apollo.queries.domainName.loading;
    },
    isVerificationLoading() {
      return (
        this.verificationInProgress || this.$apollo.queries.salesChannel.loading
      );
    },
  },
  methods: {
    openShareModal() {
      this.$modal.show(
        WidgetModal,
        {
          businessId: this.businessId,
          code: this.widgetScript,
          anchor: this.widgetAnchor,
        },
        {
          width: "682px",
          maxWidth: 682,
          height: 558,
          adaptive: true,
        }
      );
    },
    async verifyWebsite() {
      this.verificationInProgress = true;

      try {
        const res = await this.$apollo.mutate({
          mutation: VERIFY_SALES_CHANNEL,
          variables: {
            businessId: this.businessId,
            salesChannelEnum: channels.WEBSITE,
          },
        });

        if (has("errors", res)) throw res.errors;

        new Toast().create({
          type: "success",
          tite: this.$t("label.success"),
          text: this.$t("c.channels.common.verification_success_message"),
        });

        this.$apollo.queries.salesChannel.refetch();
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.common.verification_error_message"),
        });
      } finally {
        this.verificationInProgress = false;
      }
    },
    async updateDomainName() {
      this.errorMessageDomain = null;
      this.domainLoading = true;

      try {
        const response = await this.$apollo.mutate({
          mutation: UPDATE_DOMAIN_NAME_FOR_BUSINESS,
          variables: {
            businessId: this.businessId,
            domainName: this.domainName,
          },
        });

        if (has("errors", response)) {
          throw response.errors;
        }

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.url_successfully_saved"),
        });
      } catch (errors) {
        const validationErrors = pathOr(
          [null],
          ["0", "extensions", "validation", "domainName"],
          errors
        );

        this.errorMessageDomain = validationErrors[0];

        if (!this.errorMessageDomain) {
          new Toast().create({
            type: "error",
            title: this.$t("label.oops"),
            text: this.$t("message.general_failure"),
          });

          throw errors;
        }
      } finally {
        this.domainLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.install {
  padding: 44px 30px;

  &__container {
    width: 540px;
  }

  &__title {
    font-size: 20px;
    margin-top: 40px;
  }

  &__description {
    font-size: 15px;
    margin-bottom: 40px;
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding: 15px;
    border-radius: 4px;
    background: rgba(0, 168, 248, 0.1);
    border: 1px solid rgba(0, 168, 248, 0.5);

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &-title {
      font-size: 17px;
      margin-bottom: 8px;
    }

    &-description {
      font-size: 14px;
      line-height: 140%;
      color: #84a0b2;
      margin-bottom: 0;
    }
  }
}
</style>
